export const AppUtilityFunctions = {
	getRedirectUrl() {
		let redirectUrl = window.location.origin + '/login';
		return redirectUrl;
	},
	getDataTableColumns(tableData: any, sort?: Boolean, center?: Boolean) {
		if (tableData?.length > 0) {
			const transformedData = Object.values(tableData).map((key: any) => ({
				selector: key,
				name: key.replace(/_/g, ' '),
				sortable: sort ? true : false,
				center: center ? true : false,
			}));
			return transformedData;
		}
	},
	calculateTimeDifferenceInSeconds(date1: any, date2: any) {
		const timeDifferenceInMilliseconds = date1 - date2;
		const timeDifferenceInSeconds = Math.floor(
			timeDifferenceInMilliseconds / 1000
		);
		return timeDifferenceInSeconds;
	},

	truncateText(text: any, maxLength: any) {
		if (typeof text !== 'string' || typeof maxLength !== 'number') {
			return '';
		}
		if (text.length > maxLength) {
			return text.substring(0, maxLength) + '...';
		}
		return text;
	},
	convertPercentageStringToNumber(percentageString: string) {
		const numberString = percentageString
			? percentageString.replace('%', '')
			: '0';
		const number = parseFloat(numberString);
		return isNaN(number) ? 0 : number;
	},

	getCurrentDateTimeWithAddedMinutes(minutesToAdd: any) {
		const currentDateTime = new Date(); // Get the current date and time
		const newDateTime = new Date(
			currentDateTime.getTime() + minutesToAdd * 60 * 1000
		); // Add minutes
		return newDateTime;
	},
	removeSpacesAndAddUnderscores(inputString: string) {
		const str = inputString;
		const replStr = ' ';
		const newStr = '_';
		const updatedStr = str.replace(replStr, newStr);
		return updatedStr;
	},

	parseDateStringToDate(dateString: any) {
		// Parse the date string into a Date object
		return new Date(dateString);
	},
};
