import React, { useEffect, useRef, useCallback } from 'react';
import {
	Box,
	FormControl,
	Grid,
	IconButton,
	useTheme,
	FormControlLabel,
	RadioGroup,
	Radio,
	Typography,
} from '@mui/material';
import './AdvancedFilter.scss';
import Dropdown from '../Dropdown/Dropdown';
import AddIcon from '@mui/icons-material/Add';
import Input from '../Input/Input';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '../Button/Button';
import AlertDialog from '../Dialog/Dialog';
import useToast from '../../components/Toast/hooks/useToast';
import ToolTipOnText from '../Tooltip/Tooltip';
import { validateFilterCondition } from './CommonFilterFunction';

interface FilterDialogProps {
	setAdvanced?: (filters: any) => void;
	selectFilters: any;
	ResetFilters: any;
	setResetAllStates: any;
	setGlobalFilter?: any;
	setFetchFilters?: any;
	settingFilter?: any;
	filtersApplied?: any;
}
const AdvancedFilterDialog: React.FC<FilterDialogProps> = ({
	ResetFilters,
	setAdvanced,
	selectFilters,
	setResetAllStates,
	setGlobalFilter,
	settingFilter,
	filtersApplied,
}) => {
	const theme = useTheme();
	const toaster: any = useToast();
	const [parameter, setParameter] = React.useState('');
	const [operation, setOperation] = React.useState('');
	const [value, setValue] = React.useState('');
	const [filterCondition, setFilterCondition] = React.useState('');
	const [isOpen, setIsOpen] = React.useState(false); // State to manage visibility
	const [relationalOperator, setRelationalOperator] = React.useState('AND');
	const resetFilterRef: any = useRef();
	const [advancedFilters, setAdvancedFilters] = React.useState('');
	const inputRef = useRef(null); // Create a ref for the input field
	const [disableInput, setDisableInput] = React.useState(true); // State to manage visibility
	const [errors, setErrors] = React.useState({
		parameter: '',
		operation: '',
		value: '',
	});

	// Available parameters and operations
	const parameters = ['Channel', 'Source', 'Campaign', 'ad_group', 'Keyword'];
	const operators = [
		'Containing',
		'Exactly matching',
		'Starts with',
		'Ends with',
		'Does not Contain',
		'Not Equal',
	];

	useEffect(() => {
		if (!selectFilters || typeof selectFilters.advanceFilters !== 'string') {
			// Handle case when selectFilters or advanceFilters is undefined or not a string
			setFilterCondition('');
			setIsOpen(false);
			if (setAdvanced) setAdvanced(''); // Clear advanced filter if necessary
			return;
		}

		if (selectFilters.advanceFilters.trim() === '') {
			if (setAdvanced) setAdvanced(''); // Clear advanced filter directly
		} else {
			// Extract the first logical operator if it exists
			const match = selectFilters.advanceFilters.trim().match(/^(AND|OR)/);
			const firstOperator = match ? match[1] : 'AND'; // Default to 'AND' if no operator is found
			setRelationalOperator(firstOperator);

			// Process and clean the advanceFilters string
			const filterData = selectFilters.advanceFilters
				.trim()
				.replace(/^(AND|OR)\s*\(/, '') // Remove the first "AND/OR" and opening bracket
				.replace(/\)$/, ''); // Remove the last closing bracket

			setFilterCondition(filterData);
			setIsOpen(true);
		}
	}, [selectFilters]);

	useEffect(() => {
		if (filterCondition) {
			setDisableInput(false);
		} else {
			setDisableInput(true);
		}
		setResetAllStates(false);
		if (setAdvanced) {
			if (relationalOperator && filterCondition) {
				const newAdvancedFilter = `${relationalOperator} (${filterCondition})`;
				setAdvanced(newAdvancedFilter);
			}
		}
	}, [filterCondition, relationalOperator, setAdvanced]);

	useEffect(() => {
		if (ResetFilters) {
			handleClear();
		}
	}, [ResetFilters]);

	useEffect(() => {
		if (parameter && operation && value) {
			setGlobalFilter({ para: parameter, opera: operation, value });
			settingFilter(false);
		} else if (parameter || operation || value) {
			settingFilter(true);
		} else {
			setGlobalFilter({ para: '', opera: '', value: '' });
			settingFilter(false);
		}
	}, [parameter, operation, value]);

	useEffect(() => {
		if (filtersApplied) createFilterCondition();
	}, [filtersApplied]);

	const createFilterCondition = () => {
		// Check for empty fields and set error messages
		setErrors({
			parameter: !parameter ? 'Required' : '',
			operation: !operation ? 'Required' : '',
			value: !value ? 'Required' : '',
		});

		// Exit early if any of the fields are empty
		if (!parameter || !operation || !value) return;

		// Define operation mappings
		const operationsMap: Record<string, string> = {
			'Exactly matching': '=',
			Containing: `LIKE '%${value}%'`,
			'Starts with': `LIKE '${value}%'`,
			'Ends with': `LIKE '%${value}'`,
			'Does not Contain': `NOT LIKE '%${value}%'`,
			'Not Equal': '!=',
		};

		// Get the corresponding SQL operation or fallback to custom operation
		const operationText = operationsMap[operation] || operation;

		// Build the filter condition
		const condition = operationText.includes('LIKE')
			? `${parameter} ${operationText}`
			: `${parameter} ${operationText} '${value}'`;

		// Check if the filter condition already has an unclosed bracket
		const unclosedBrackets = /\([^\)]*$/;

		// If there's an unclosed bracket in the existing filter condition, prevent appending relationalOperator
		if (unclosedBrackets.test(filterCondition)) {
			// Set the filter condition without adding the relational operator
			setFilterCondition((prev) => (prev ? `${prev} ${condition}` : condition));
		} else {
			// Set the filter condition, appending relationalOperator if there are no unclosed brackets
			setFilterCondition((prev) =>
				prev ? `${prev} ${relationalOperator} ${condition}` : condition
			);
		}

		// Clear the selections
		setParameter('');
		setOperation('');
		setValue('');
		setErrors({ parameter: '', operation: '', value: '' }); // Clear errors after successful submission
	};

	const andOrOperator = (event: any) => {
		let label = event.target.value;
		setRelationalOperator(label);
	};

	// Function to add logical operators (prevents simultaneous AND/OR operators)
	const addLogicalOperator = (operator: string) => {
		if (filterCondition.trim()) {
			// Split the condition into tokens
			const tokens = filterCondition.trim().split(/\s+/);
			const lastToken = tokens[tokens.length - 1];

			// Prevent adding consecutive operators
			if (lastToken === 'AND' || lastToken === 'OR') {
				// Replace the last operator if needed
				setFilterCondition((prev) =>
					prev.trim().replace(/(AND|OR)$/, operator)
				);
			} else {
				// Add the operator if the last token is not another operator
				setFilterCondition((prev) => `${prev.trim()} ${operator}`);
			}
		} else {
			// If the condition is empty, allow the first operator to be added
			setFilterCondition(operator);
		}
	};

	// Function to handle user typing in the filter condition
	const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		if (setAdvanced) {
			const newAdvancedFilter = `${inputValue}`;
			setAdvanced(newAdvancedFilter); // Also set the advanced filter externally if needed
		}
		setFilterCondition(inputValue);
	};

	// Function to handle value input change (numeric only)
	const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const numericValue = event.target.value;
		setValue(numericValue);
	};

	// Function to clear the form fields
	const handleClear = () => {
		setParameter('');
		setOperation('');
		setValue('');
		setFilterCondition('');
		setRelationalOperator('AND');
		setAdvancedFilters('');
		if (setAdvanced) {
			setAdvanced('');
		}
		setErrors({
			parameter: '',
			operation: '',
			value: '',
		});
	};

	// Toggle the visibility of the content
	const toggleContent = () => {
		setIsOpen((prev) => !prev);
	};

	return (
		<div className='advanced_filterModal'>
			<Grid display={'flex'} alignItems={'center'} mb={2}>
				<Typography variant='h4'> {'Advance Filter'}</Typography>
				<IconButton aria-label='toggle' onClick={toggleContent}>
					{isOpen ? (
						<KeyboardArrowUpIcon color='primary' />
					) : (
						<KeyboardArrowDownIcon color='primary' />
					)}
				</IconButton>
			</Grid>

			{isOpen && (
				<>
					<Grid
						style={{
							borderRadius: '25px',
							border: '0.5px solid grey',
							padding: '20px',
						}}
					>
						<div className='advanced_filterModal__dimensionBlock__conditions'>
							<Typography variant='h6' component='span' align='right'>
								Select Condition
							</Typography>
							<FormControl
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									alignItems: 'center',
									// marginTop: '1.5rem',
									flexDirection: 'row',
								}}
							>
								<RadioGroup
									row
									style={{ marginLeft: '3rem' }}
									defaultValue={1}
									onChange={andOrOperator}
									value={relationalOperator}
								>
									<FormControlLabel
										value={'AND'}
										control={<Radio />}
										label='AND'
									/>
									<FormControlLabel
										value={'OR'}
										control={<Radio />}
										label='OR'
									/>
								</RadioGroup>
							</FormControl>
						</div>
						{/* Filter Parameter, Operation, and Value */}
						<Grid
							container
							spacing={3}
							xs={12}
							sx={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}
						>
							<Grid item xs={1.8}>
								<Typography variant='h6' component='span' align='left'>
									{'Filter Clauses'}
								</Typography>
							</Grid>
							<Grid item xs={12} sm={3}>
								<FormControl fullWidth>
									<Dropdown
										itemList={parameters}
										label='Parameter'
										valueSelector='value'
										name='parameter'
										onChange={(e: any) =>
											setParameter(e.target.value as string)
										}
										selectedValue={parameter}
										labelName='Parameter'
									/>
									{errors.parameter && !parameter && (
										<span className='advanced_filterModal__error-message'>
											Required
										</span>
									)}
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={3}>
								<FormControl fullWidth>
									<Dropdown
										itemList={operators}
										label='Operation'
										valueSelector='value'
										name='operation'
										onChange={(e: any) =>
											setOperation(e.target.value as string)
										}
										selectedValue={operation}
										labelName='Operation'
									/>
									{errors.operation && !operation && (
										<span className='advanced_filterModal__error-message'>
											Required
										</span>
									)}
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={3}>
								<FormControl fullWidth>
									<Input
										name='value'
										value={value}
										placeholder='Enter Value'
										label='Value'
										onChange={handleValueChange}
									/>
									{errors.value && !value && (
										<span className='advanced_filterModal__error-message'>
											Required
										</span>
									)}
								</FormControl>
							</Grid>

							<Grid item xs={12} sm={1}>
								<span
									style={{
										border: '1px solid grey',
										borderRadius: '50%',
										display: 'flex',
										height: '2.5rem',
										width: '2.5rem',
										alignItems: 'center',
										justifyContent: 'center',
										cursor: 'pointer',
									}}
								>
									<ToolTipOnText title='Add Condition'>
										<AddIcon
											color='primary'
											fontSize='large'
											onClick={createFilterCondition}
										/>
									</ToolTipOnText>
								</span>
							</Grid>
						</Grid>

						{/* Logical Operator Buttons */}
						<Box display='flex' justifyContent='center' gap={2} mt={3}>
							<Button
								variant='contained'
								onClick={() => addLogicalOperator('AND')}
								btnText='AND'
								type={''}
								disable={!filterCondition}
							/>
							<Button
								variant='contained'
								onClick={() => addLogicalOperator('OR')}
								btnText='OR'
								type={''}
								disable={!filterCondition}
							/>

							<Button
								variant='contained'
								onClick={() => setFilterCondition((prev) => prev + ' (')}
								btnText='('
								type={''}
							/>
							<Button
								variant='contained'
								onClick={() => setFilterCondition((prev) => prev + ' )')}
								btnText=')'
								type={''}
							/>
						</Box>

						{/* Display Current Filter Condition */}
						<Grid
							container
							spacing={3}
							mt={2}
							style={{ display: 'flex', alignItems: 'center' }}
						>
							<Grid item xs={8}>
								<Input
									disabled={disableInput}
									label='Current Filter Conditions'
									name='filterCondition'
									value={filterCondition}
									onChange={handleFilterChange}
								/>
							</Grid>
							<Grid item xs={4}>
								<Box
									display='flex'
									flexDirection='row'
									alignItems='flex-end'
									gap={2}
								>
									<Button
										variant='outlined'
										onClick={handleClear}
										btnText='Clear'
										type={''}
									/>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</>
			)}
		</div>
	);
};

export default AdvancedFilterDialog;
